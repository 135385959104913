import React from "react"
import Layout from "../components/templates/Layout/Layout"
import SEO from "../components/atoms/SEO/SEO"
import PageWidth from "../components/templates/PageWidth/PageWidth"

const NotFoundPage = () => {
  const [title, setTitle] = React.useState("Evaluating")
  const [h1, setH1] = React.useState("Evaluating link...")
  const [text, setText] = React.useState("Is this a proper link?")

  React.useEffect(() => {
    setTitle("Page not found")
    setH1("Oooooops")
    setText("Page not found")
  }, [])

  return (
    <Layout>
      <SEO title={title} />
      <PageWidth>
        <h1>{h1}</h1>
        <p>{text}</p>
      </PageWidth>
    </Layout>
  )
}

export default NotFoundPage
